import { useTitle } from "../../hooks/useTitle";
import "./home.css"

const Home = () => {
    useTitle("CFBI Home")

    return (
        <div className="home">
            <h1>Welcome to Central Florida Bible Institute</h1>
        </div>
    )
}

export default Home;