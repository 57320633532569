import { Link } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle"; 
import "./more.css"

const More = () => {
    useTitle("Learn More")

    return (
        <div  className='more'>
            <h1>More</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas exercitationem labore minima impedit, voluptatibus veritatis reprehenderit facilis quod, laborum modi laudantium vel cum dignissimos tenetur illum praesentium voluptate deserunt. Tempore?</p>
            <hr />
            <div className="future">
                <h2>Our Plans for the Future</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt omnis minus numquam error adipisci illum autem! Consequuntur molestiae asperiores qui iste? Neque sunt nulla repudiandae debitis tenetur, ut qui earum!</p>
            </div>
            <hr />
            <div className="contactUs">
                <p>If you have any suggestions, questions, or just want to say "Hi"</p>
                <br />
                <button><Link to="/contact" aria-label="contact us">Contact Us</Link></button>
            </div>
        </div>
    )
}

export default More;