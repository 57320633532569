import { Link } from "react-router-dom"


// Import Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faXTwitter, faSquareInstagram } from "@fortawesome/free-brands-svg-icons"
import { faPhone, faEnvelope, faMap } from "@fortawesome/free-solid-svg-icons"

import "./footer.css"

const Footer = () => {
    let currentYear = new Date().getFullYear()

    return (
        <div className="footer">
            <div className="content">
                <div className="info">
                    <ul>
                        <li><strong>Information</strong></li>
                        <li><FontAwesomeIcon icon={faPhone} /> :  <a href="tel:407-696-5110">(407) 696-5110</a></li>
                        <li><FontAwesomeIcon icon={faEnvelope} /> :  <a href="mailto:  starlight@starlightbaptist.com">starlight@starlightbaptist.com</a></li>
                        <li><FontAwesomeIcon icon={faMap} /> :  <a href="https://maps.app.goo.gl/FgLVV62ULG2ihLJB6" >
                            350 Hays Rd, Winter Springs, FL  32708
                        </a></li>
                    </ul>
                </div>
                <div className="social">
                    <ul>
                        <li><strong>Connect With Us</strong></li>
                        <li>FB</li>
                        <li>X</li>
                        <li>IG</li>
                    </ul>
                </div>
                <div className="links">
                    <ul>
                        <li><strong>More</strong></li>
                        <li><Link to="/Beliefs" aria-label="our beliefs">What We Believe</Link></li>
                        <li><a href="https://www.starlightbaptist.com/">Starlight Baptist Church</a></li>
                    </ul>
                </div>
            </div>
            <hr />
            <div className="copy">
                <p>&copy;{currentYear} Central Florida Bible Institute</p>
            </div>
        </div>
    )
}

export default Footer;