import { Link } from "react-router-dom";

const PcNav = () => {

    return (
        <>
            <Link to="/" aria-label="honme">Home</Link>
            <Link to="/About" aria-label="about cfbi">About</Link>
            <Link to="/Contact" aria-label="contact us">Contact Us</Link>
            <Link to="/More" aria-label="learn more">Learn More</Link>
        </>
    )
}

export default PcNav;