
// Import Components
import Card from "../../components/card";

// Import Hooks and CSS
import { useTitle } from "../../hooks/useTitle";
import "./classes.css"

// Import ClassList data
import { ClassList } from "../../data/classes.js"

const Classes = () => {
    useTitle("CFBI Classes")

    return (
        <div className="classes">
            <h1>Classes & Schedules</h1>
            <div className="schedules">
                <h2>Schedules for 2024-2025</h2>
                <p><strong>Fall Semester:</strong>  date - date</p>
                <p><strong>Spring Semester:</strong>  date - date</p>
                <p>Classes are on Monday nights throughout the semester</p>
            </div>
            <hr />
            <div className="classList">
                <h2>List of Current Classes</h2>
                <div className="list">
                    { ClassList.map((Class) => (
                        <Card 
                            key={Class.id}
                            course={Class.name}
                            about={Class.about}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Classes;