import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars"
import { faX } from "@fortawesome/free-solid-svg-icons/faX"
import "./mob_nav.css"

const MobNav = () => {
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(false)
  }, [location])

  return (
    <div className="menuBar">
        <div className="cfbi">
          <h3>Central Florida Bible Institute</h3>
        </div>
        <div className="menuButton">
            <button onClick={() => setIsOpen(!isOpen)} aria-label={isOpen ? "close menu" : "open menu"} >{ isOpen ? <FontAwesomeIcon icon={faX} size="2xl" /> : <FontAwesomeIcon icon={faBars} size="2xl" />}</button>
        </div>
        { isOpen ?
            <div className="menuList">
                <ul>
                    <li><Link to="/" aria-label="home">Home</Link></li>
                    <li><Link to="/About" aria-label="about">About</Link></li>
                    <li><Link to="/Classes" aria-label="classes">Classes</Link></li>
                    <li><Link to="/Contact" aria-label="contact">Contact Us</Link></li>
                    <li><Link to="/More" aria-label="learn more">Learn More</Link></li>
                </ul>
            </div>
            : null
        }
    </div>
  )
}

export default MobNav;