import { useRef } from "react";
import { useTitle } from "../../hooks/useTitle";
import "./contact.css"

const Contact = () => {
    useTitle("Contact CFBI")

    const form = useRef()

    const sendEmail = () => {
        console.log("email sent")
    }

    return (
        <div className="contact">
            <h1>Contact CFBI</h1>
            <div className="wrapper">
                <div className="contactText">
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit delectus dolor quis, tempore exercitationem commodi nostrum temporibus sit aliquam! Commodi vero magnam sed cum cumque, voluptates ea? Temporibus, nesciunt deleniti.</p>
                </div>
                <div className="contactForm">
                    <form ref={form} onSubmit={sendEmail}>                    
                        <input type="text"  name="Name" placeholder="Name" required />                    
                        <input type="email" name="Email" placeholder="Email" required />                    
                        <textarea name="Message" rows={5} required />
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact;