import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import "./banner.css"

const Banner = ({onClick}) => {

    return (
        <div className="banner">
            <div className="bannerText">
                <p>Register now for spring semester!</p>
            </div>
            <div className="bannerButton">
                <button onClick={onClick}><FontAwesomeIcon icon={faX} /></button>
            </div>
        </div>
    )
}

export default Banner;