import { useTitle } from "../../hooks/useTitle";

const Beliefs = () => {
    useTitle("CFBI Beliefs")

    return (
        <div className="beliefs">
            <h1>What We Belive</h1>
        </div>
    )
}

export default Beliefs;