export const InstructorList = [
    {
        "id": 1,
        "title": "Pastor",
        "name": "Sam Riggs",
        "pic": "person_ph.jpg",
        "classes": "New Testament Survey"
    },
    {
        "id": 2,
        "title": "Pastor",
        "name": "Time Jordan",
        "pic": "person_ph.jpg",
        "classes": "Bible Doctrine"
    },
    {
        "id": 3,
        "title": "Mr.",
        "name": "Johnathan Connell",
        "pic": "person_ph.jpg",
        "classes": "Old Testament Survey"
    },
]
