import { useState } from "react";
import { Outlet } from "react-router-dom";
import "./layout.css"

// Import components
import Banner from "../banner";
import Header from "../header";
import Footer from "../footer";
import MobNav from "../mob_nav";
import PcNav from "../pc_nav";

import { useWindowSize } from "../../hooks/useWindowSize";

const Layout = () => {
    const windowSize = useWindowSize() 
    const [isBanner, setIsBanner] = useState(true)

    const dismissBanner = () => {
      setIsBanner(false)
    }

    return (
        <>
          {isBanner ? <Banner onClick={dismissBanner} /> : null}
          {windowSize.width > 780 ? <Header /> : null}
          {windowSize.width > 780 ? <PcNav /> : <MobNav />}
          <div className="pageWrapper">
            <Outlet />
          </div>          
          <Footer />  
        </>
    )
}

export default Layout;