export const ClassList = [
    {
        "id": 1,
        "name": "Old Testament Survey",
        "about": "Lorem ipsum odor amet, consectetuer adipiscing elit. Bibendum diam potenti molestie platea class lacus laoreet enim. Magnis aenean parturient pharetra enim platea. Consequat ac orci natoque eu nostra. Dis magnis aliquet ex duis malesuada. Nascetur velit inceptos ligula fermentum gravida vitae proin. Pellentesque euismod nullam potenti elementum bibendum pretium. Condimentum aptent fringilla dictum consequat nulla pellentesque."
    },
    {
        "id": 2,
        "name": "New Testament Survey",
        "about": "Lorem ipsum odor amet, consectetuer adipiscing elit. Bibendum diam potenti molestie platea class lacus laoreet enim. Magnis aenean parturient pharetra enim platea. Consequat ac orci natoque eu nostra. Dis magnis aliquet ex duis malesuada. Nascetur velit inceptos ligula fermentum gravida vitae proin. Pellentesque euismod nullam potenti elementum bibendum pretium. Condimentum aptent fringilla dictum consequat nulla pellentesque."
    },
    {
        "id": 3,
        "name": "Bible Doctrine",
        "about": "Lorem ipsum odor amet, consectetuer adipiscing elit. Bibendum diam potenti molestie platea class lacus laoreet enim. Magnis aenean parturient pharetra enim platea. Consequat ac orci natoque eu nostra. Dis magnis aliquet ex duis malesuada. Nascetur velit inceptos ligula fermentum gravida vitae proin. Pellentesque euismod nullam potenti elementum bibendum pretium. Condimentum aptent fringilla dictum consequat nulla pellentesque."
    }
]

// export default ClassList;