import { useTitle } from "../../hooks/useTitle";

const NoPage = () => {
    useTitle("404 Not Found")

    return (
        <>
            <h1>NoPage</h1>
        </>
    )
}

export default NoPage;