import "./card.css"

const Card = (props) => {

    if (props.type == null) {
        
        return (
            <div className="card">
                <h2 className="course">{props.course}</h2>
                <hr />
                <div className="about">
                    <p>{props.about}</p>
                </div>
            </div>
        )
    }

    if (props.type === "bio") {

    return (
        <div className="card">
            <div className="instructor">
                <div className="instructorPic">
                    <img src={"./images/" + props.pic} alt="" />
                </div>
                <div className="instructorInfo">
                    <p>{props.title} {props.name}</p>
                    <p>Classes:<br />{props.classes}</p>
                </div>
            </div>
        </div>
    )
    }
}

export default Card;