import Card from "../../components/card";
import { useTitle } from "../../hooks/useTitle";
import "./about.css"

// Import Data
import { InstructorList } from "../../data/instructors.js";

const About = () => {
    useTitle("About CFBI")

    return (
        <div className="about">
            <h1>About CFBI</h1>
            <div className="aboutText">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. In nobis autem reprehenderit ipsam minus eius laborum aperiam beatae quia est aliquid quas nihil quisquam tempore pariatur, sit eligendi vitae. Deserunt.</p>
            </div>
            <div className="aboutFaculty">
                <h2>Faculty</h2>
                <div className="facultyList">
                    { InstructorList.map((Instructor) => (
                        <Card 
                            type="bio"
                            key={Instructor.id}
                            title={Instructor.title}
                            name={Instructor.name}
                            pic={Instructor.pic}
                            classes={Instructor.classes}
                        />
                    ))}
                </div>
                
            </div>
        </div>
    )
}

export default About;