import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import "./global.css"

// Import Components
import Layout from './components/layout';

// Import Pages
import Home from './pages/home';
import About from './pages/about';
import Classes from './pages/classes';
import Contact from './pages/contact';
import More from './pages/more';
import Beliefs from './pages/beliefs';
import NoPage from './pages/noPage';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="About" element={<About />} />
          <Route path='Classes' element={<Classes />} />
          <Route path="More" element={<More />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="Beliefs" element={<Beliefs />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />)
